/* eslint-disable no-underscore-dangle */
/* eslint-disable indent */
/* eslint-disable react/jsx-wrap-multilines */
import {
  Alert,
  Button,
  Col,
  Collapse,
  Modal,
  notification,
  Row,
  Spin,
  Tabs,
} from 'antd';
import Search from 'antd/lib/input/Search';
import { BrandDBEntity } from 'components/BrandForm/interface';
import { nullishProductBrand } from 'components/BrandsAutocomplete/interface';
import { ProductForm } from 'components/ProductForm/ProductForm';
import UnitMovements from 'components/UnitMovements/UnitMovements';
import {
  defaultVariantFormValues,
  VariantFormValues,
} from 'components/VariantForm/interface';
import { VariantForm } from 'components/VariantForm/VariantForm';
import VariantHistory from 'components/VariantHistory/VariantHistory';
import { VariantContent } from 'containers/Products/VariantList/interface';
import { VariantTable } from 'containers/Products/VariantList/VariantTable';
import { ROUTES } from 'helpers/constants/routes';
import useModuleCRUD from 'helpers/hooks/useModuleCRUD/useModuleCRUD';
import useNotifications from 'helpers/hooks/useNotifications';
import useRoleAccess from 'helpers/hooks/useRoleAccess/useRoleAccess';
import { buildVariantComboParams } from 'helpers/utils/buildVariantComboParams';
import { compareAttributeLevels } from 'helpers/utils/compareIdObjects';
import { imagesToUploadFiles } from 'helpers/utils/ImagesToUploadFile';
import { parseVariantName } from 'helpers/utils/parseVariantName';
import { debounce, isEmpty } from 'lodash';
import VariantStockContainer from 'modules/Variant/containers/VariantStockContainer';
import { VariantStockUpdateProvider } from 'modules/Variant/context/StockUpdate.context';
import { Title } from 'pages/App/Layout.sty';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Variant, VariantStatus } from 'services/product/interface';
import { recountVariant } from 'services/variant/variant.service';
import { DEFAULT_VAT_PERCENTAGE } from '../../../helpers/constants/variantForm';
import { ProductFormContent } from '../CreateProduct/interface';
import { DEBOUNCED_SEARCH_MS } from './constants';
import useUpdateProduct from './hooks/useUpdateProduct';
import { DisplayVariant } from './interface';
import {
  SubmitButtonCol,
  SubmitButtonRow,
  TabsContainer,
  UpdateProductContainer,
  UpdateProductModal,
  UpdateProductPanel,
} from './UpdateProduct.sty';

const { Panel } = Collapse;

const defaultVariant: DisplayVariant = {
  id: '',
  name: '',
  variantSku: '',
  stock: {
    availableInWarehouse: 0,
    notAvailableInWarehouse: 0,
    inWarehouse: 0,
    available: 0,
    block: 0,
    sold: 0,
  },
  price: 0,
  discount: undefined,
  cost: 0,
  urlName: '',
  productId: '',
  attributeId: '',
  description: '',
  rack: '',
  mps: undefined,
  level: '',
  position: '',
  hall: '',
  images: [],
  tags: [],
  designTags: '',
  keywords: [],
  video: '',
  isTaxExcluded: false,
  commission: 0,
  vatPercentage: DEFAULT_VAT_PERCENTAGE,
};

export const UpdateProduct = () => {
  const { id } = useParams();
  const {
    readByParamQuery: {
      mutate: getProductByUrlName,
      data: product,
      isLoading: isLoadingProduct,
    },
    updateMutation: {
      isLoading: loadingUpdate,
      mutateAsync: updateProductAsync,
    },
  } = useModuleCRUD('products');
  const {
    createMutation: { mutateAsync: createVariantAsync },
    updateMutation: { mutateAsync: updateVariantAsync },
    deleteMutation: { mutateAsync: deleteVariantAsync },
    readByParamQuery: {
      mutateAsync: getVariantAsync,
      isLoading: isVariantFormLoading,
      data: actualVariant,
    },
  } = useModuleCRUD('variant');
  const {
    createMutation: { mutateAsync: createComboAsync },
    updateMutation: { mutateAsync: updateComboAsync },
  } = useModuleCRUD('combo');

  const { t } = useTranslation();
  const { hasAccess } = useRoleAccess(`routes.${ROUTES.updateProduct}`);
  const navigate = useNavigate();
  const [recountModalVisible, setRecountModalVisible] =
    useState<boolean>(false);
  const [isLoadingRecount, setIsLoadingRecount] = useState<boolean>(false);

  useEffect(() => {
    if (!hasAccess) {
      notification.error({
        message: t('noAccessText.youDontHaveAccess'),
      });
      navigate(ROUTES.home);
    }
  }, [hasAccess, navigate, t]);

  const {
    productFormContentState: [productFormContent],
    variantFormValuesState: [variantFormValues, setVariantFormValues],
    variantDataState: [variantData, setVariantData],
    targetVariantState: [targetVariant, setTargetVariant],
    showVariantDrawerState: [showVariantDrawer, setShowVariantDrawer],
    createVariantState: [createVariant, setCreateVariant],
    createComboState: [createCombo, setCreateCombo],
    attributeParams,
    changedAttributeLevelsState: [
      changedAttributeLevels,
      setChangedAttributeLevels,
    ],
    setStockChange,
  } = useUpdateProduct(id || '', getProductByUrlName, product);

  const [brandValue, setBrandValue] = useState<BrandDBEntity>(
    productFormContent.brand as BrandDBEntity,
  );
  const [comboLoading, setComboLoading] = useState<boolean>(false);
  const [isUpdate, setIsUpdate] = useState<boolean>(true);
  const [variantsList, setVariantsList] = useState<VariantContent[] | null>(
    null,
  );

  const closeVariantDrawer = useCallback(() => {
    setShowVariantDrawer(false);
    setIsUpdate(true);
    if (
      variantData &&
      variantData.length > 0 &&
      !comboLoading &&
      variantFormValues
    ) {
      const newVariants = variantData.map((variant) => {
        if (variantFormValues.id && variantFormValues.id === variant.id) {
          try {
            const attr = variantFormValues.attributes?.map((v) => v.name);
            return {
              ...variantFormValues,
              images: variant.images,
              name: `${variantFormValues.name}${
                attr?.length ? '-' : ''
              }${attr?.join(' - ')}`,
            } as unknown as VariantContent;
          } catch {
            return variant;
          }
        }
        return variant;
      });
      setVariantData(newVariants);
    }
  }, [
    comboLoading,
    setShowVariantDrawer,
    setVariantData,
    variantData,
    variantFormValues,
  ]);

  const { notifySuccess, notifyError } = useNotifications({
    translationFunction: t,
  });

  const onSubmitProduct = useCallback(
    async (values: ProductFormContent) => {
      const variants = product.variants || [];
      if (variants.length > 0) {
        variants[0] = {
          ...variants[0],
        };
      }
      const areChangedAttributeLevels = compareAttributeLevels(
        product.attributeLevels,
        values.attributeLevels || {},
      );
      const productReq = {
        ...product,
        revisionStatus: values.revisionStatus,
        name: values.name,
        description: values.description,
        categoryId: values.categoryId,
        brand: (brandValue?.id ?? '').length > 0 ? brandValue : {},
        variants,
        attributeLevels: areChangedAttributeLevels
          ? values.attributeLevels
          : undefined,
      };
      try {
        await updateProductAsync(productReq);
        if (areChangedAttributeLevels) {
          notification.warning({
            message: t('productForm.modal.message.changedLevels'),
            duration: null,
          });
          setChangedAttributeLevels(true);
          getProductByUrlName(id);
          return;
        }
        getProductByUrlName(id);
        setChangedAttributeLevels(false);
        notifySuccess('productForm.modal.message.product.success');
      } catch (error) {
        setChangedAttributeLevels(false);
        notifyError('productForm.modal.message.product.error');
      }
    },
    [
      brandValue,
      getProductByUrlName,
      id,
      notifyError,
      notifySuccess,
      product,
      setChangedAttributeLevels,
      t,
      updateProductAsync,
    ],
  );

  const onSubmitCombo = useCallback(
    async (values: VariantFormValues) => {
      if (createCombo) {
        try {
          await createComboAsync(
            buildVariantComboParams({
              values,
              product,
              isCombo: true,
            }),
          );
          notifySuccess('productForm.modal.message.variant.success');
        } catch (error) {
          notifyError('productForm.modal.message.variant.error');
        }
      }

      if (!createCombo) {
        try {
          await updateComboAsync(
            buildVariantComboParams({
              values,
              product,
              isCombo: true,
              targetVariant,
            }),
          );
          notifySuccess('productForm.modal.message.variant.success');
        } catch (error) {
          notifyError('productForm.modal.message.variant.error');
        }
      }
      setShowVariantDrawer(false);
      getProductByUrlName(id);
    },
    [
      createCombo,
      createComboAsync,
      getProductByUrlName,
      id,
      notifyError,
      notifySuccess,
      product,
      setShowVariantDrawer,
      targetVariant,
      updateComboAsync,
    ],
  );

  const onSubmitVariant = useCallback(
    async (values: VariantFormValues) => {
      if (createVariant) {
        try {
          await createVariantAsync(
            buildVariantComboParams({
              values,
              product,
            }),
          );
          notifySuccess('productForm.modal.message.variant.success');
        } catch (error) {
          notifyError('productForm.modal.message.variant.error');
        }
      }

      if (!createVariant) {
        try {
          let variantBody = buildVariantComboParams({
            values,
            product,
            targetVariant,
          }) as unknown as Variant;
          if (!variantBody?.brand?.id) {
            variantBody = {
              ...variantBody,
              brand: null,
            };
          }
          await updateVariantAsync(variantBody);
          notifySuccess('productForm.modal.message.variant.success');
          setStockChange({
            variantId: targetVariant?.id ?? '',
            stock: {
              available: values.stock?.available ?? 0,
              availableInWarehouse: values.stock?.availableInWarehouse ?? 0,
              notAvailableInWarehouse:
                values.stock?.notAvailableInWarehouse ?? 0,
              inWarehouse:
                (values.stock?.availableInWarehouse ?? 0) +
                (values.stock?.notAvailableInWarehouse ?? 0),
              block: targetVariant?.stock?.block ?? 0,
            },
          });
        } catch (error) {
          notifyError('productForm.modal.message.variant.error');
        }
      }
      setVariantsList(null);
      setShowVariantDrawer(false);
      getProductByUrlName(id);
    },
    [
      createVariant,
      createVariantAsync,
      getProductByUrlName,
      id,
      notifyError,
      notifySuccess,
      product,
      setShowVariantDrawer,
      setStockChange,
      targetVariant,
      updateVariantAsync,
    ],
  );

  const onDeleteVariant = useCallback(
    async (productId: string, variantId: string) => {
      await deleteVariantAsync({ variantId, productId });
      getProductByUrlName(String(id));
    },
    [deleteVariantAsync, getProductByUrlName, id],
  );

  const onUpdateVariant = useCallback(
    async (v: VariantContent) => {
      const { id: variantId } = v;
      setVariantFormValues({ ...v, images: [] });
      setTargetVariant(v);
      setCreateVariant(false);
      setCreateCombo(false);
      setShowVariantDrawer(true);
      setComboLoading(true);
      const variant = await getVariantAsync({
        variantId,
        withComboDetail: 'with_combo_detail=true',
      });
      setVariantFormValues({
        ...variant,
        name: variant.name,
        availableQuantity: variant.stock?.availableInWarehouse ?? 0,
        totalQuantity:
          Number(variant.stock?.block || 0) +
          Number(variant.stock?.availableInWarehouse || 0),
        discount: variant.discount
          ? Number(variant.price) - Number(variant.discount)
          : Number(variant.price),
        isCombo: variant.isCombo ?? false,
        volumePrice: variant.volumePrice || [],
        images: imagesToUploadFiles(variant.images || []),
        brand:
          (variant.brand?.id ?? '').length > 0
            ? variant.brand
            : nullishProductBrand,
        status: variant?.status === VariantStatus.ACTIVE,
        mps: variant?.mps,
        cap: variant.cap ?? variant.stock?.cap ?? 0,
        isTaxExcluded: variant?.isTaxExcluded ?? false,
        vatPercentage: variant?.vatPercentage ?? '',
        stock: {
          ...variant?.stock,
          inWarehouse:
            (variant.stock?.availableInWarehouse ?? 0) +
            (variant.stock?.notAvailableInWarehouse ?? 0),
        },
      });
      setComboLoading(false);
      setIsUpdate(true);
    },
    [
      getVariantAsync,
      setCreateCombo,
      setCreateVariant,
      setShowVariantDrawer,
      setTargetVariant,
      setVariantFormValues,
      setIsUpdate,
    ],
  );

  const triggerCreateForm = useCallback(() => {
    const defaultAttributes =
      (defaultVariantFormValues.attributes?.length || 0) > 0
        ? defaultVariantFormValues.attributes
        : attributeParams.defaultValues;
    setTargetVariant(defaultVariant);
    setVariantFormValues({
      ...defaultVariantFormValues,
      attributes: defaultAttributes,
      name: parseVariantName(product?.name || '', defaultAttributes || []),
    });
    setCreateVariant(true);
    setCreateCombo(true);
    setShowVariantDrawer(true);
    setIsUpdate(false);
  }, [
    attributeParams.defaultValues,
    product?.name,
    setCreateCombo,
    setCreateVariant,
    setShowVariantDrawer,
    setTargetVariant,
    setVariantFormValues,
  ]);

  const onGoToPWA = useCallback(
    (urlName: string) => {
      window.open(
        `https://${
          process.env.NODE_ENV === 'production'
            ? 'pandas.com.co'
            : 'dev.pandas.com.co'
        }/product-detail/${String(product?.urlName)}/${urlName}`,
      );
    },
    [product?.urlName],
  );

  const onCloseLevelAlert = useCallback(
    () => setChangedAttributeLevels(false),
    [setChangedAttributeLevels],
  );

  const onRecountModal = useCallback(() => {
    setRecountModalVisible(!recountModalVisible);
  }, [setRecountModalVisible, recountModalVisible]);

  const onRecountUnits = useCallback(async () => {
    setIsLoadingRecount(true);
    try {
      const promises = product.variants.map((variant: any) => {
        return recountVariant(variant.id ?? variant._id);
      });

      await Promise.all(promises);
      setIsLoadingRecount(false);
      notifySuccess(t('recountModal.success'));
      setTimeout(() => {
        window?.location?.reload();
      }, 2000);
    } catch {
      notifyError('error');
    }
    onRecountModal();
  }, [product, t, notifySuccess, onRecountModal, notifyError]);

  const onSearch = useCallback(
    (value: string) => {
      if (isEmpty(value)) {
        setVariantsList(variantData);
        return;
      }
      const regex = new RegExp(value, 'i');
      setVariantsList(variantData.filter((v) => v.name.match(regex)));
    },
    [variantData, setVariantsList],
  );

  const debouncedSearch = useMemo(
    () => debounce(onSearch, DEBOUNCED_SEARCH_MS),
    [onSearch],
  );

  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      debouncedSearch(value);
    },
    [debouncedSearch],
  );

  const variantFormInitialValues = useMemo(() => {
    const attributes = variantFormValues?.attributes?.length
      ? variantFormValues?.attributes
      : attributeParams.defaultValues;

    if (createVariant) {
      return {
        ...variantFormValues,
        brand: brandValue,
        attributes,
      };
    }

    return {
      ...variantFormValues,
      attributes,
    };
  }, [attributeParams, brandValue, createVariant, variantFormValues]);

  useEffect(() => {
    if (variantData && variantData.length > 0 && variantsList === null) {
      setVariantsList(variantData);
    }
  }, [variantData, variantsList]);

  if (!product || isLoadingProduct || attributeParams.loadingAttributesList) {
    return (
      <Row justify="center">
        <Spin tip={t('loading')} />
      </Row>
    );
  }
  return (
    <UpdateProductContainer>
      <Title level={1}>{`${product?.name || t('undefinedProduct')}`}</Title>
      <Modal
        title={t('recountModal.title')}
        onOk={onRecountUnits}
        onCancel={onRecountModal}
        visible={recountModalVisible}
        okButtonProps={{
          loading: isLoadingRecount,
        }}
        closable={!isLoadingRecount}
        maskClosable={!isLoadingRecount}
        destroyOnClose
      >
        <p>{t('recountModal.body')}</p>
      </Modal>
      <UpdateProductModal
        title={targetVariant?.name}
        visible={showVariantDrawer}
        maskClosable
        closable
        onCancel={closeVariantDrawer}
        destroyOnClose
        footer={false}
        width="95%"
      >
        <TabsContainer>
          <Tabs.TabPane tab="Variante" key="variant">
            <VariantForm
              title={createVariant ? t('createVariant') : t('updateVariant')}
              isVariantFormLoading={isVariantFormLoading}
              variant={actualVariant}
              initialValues={variantFormInitialValues}
              onSubmitVariant={onSubmitVariant}
              onSubmitCombo={onSubmitCombo}
              comboLoading={comboLoading}
              formId="create-variant"
              isUpdate={isUpdate}
              showTitle
              labelCol={{ span: 5 }}
              wrapperCol={{
                xl: { span: 19 },
                lg: { span: 19 },
                md: { span: 19 },
              }}
              reset={createVariant}
              submitButton={
                <SubmitButtonRow gutter={16}>
                  <SubmitButtonCol span={12} offset={12}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      form="create-variant"
                    >
                      {createVariant ? t('createVariant') : t('updateVariant')}
                    </Button>
                  </SubmitButtonCol>
                </SubmitButtonRow>
              }
              attributeParams={attributeParams}
              productName={product?.name}
            />
          </Tabs.TabPane>
          {variantFormValues.id ? (
            <Tabs.TabPane tab={t('variantForm.tabs.stock')} key="inventory">
              <VariantStockUpdateProvider>
                <VariantStockContainer variant={actualVariant} />
              </VariantStockUpdateProvider>
            </Tabs.TabPane>
          ) : null}
          {variantFormValues.id ? (
            <Tabs.TabPane tab={t('variantForm.tabs.history')} key="history">
              <VariantHistory variantId={variantFormValues.id ?? ''} />
            </Tabs.TabPane>
          ) : null}
          {variantFormValues.id ? (
            <Tabs.TabPane
              tab={t('variantForm.tabs.unit-movements')}
              key="unit-movements"
            >
              <UnitMovements variantId={variantFormValues.id ?? ''} />
            </Tabs.TabPane>
          ) : null}
        </TabsContainer>
      </UpdateProductModal>
      <Collapse defaultActiveKey={[0, 1]}>
        <UpdateProductPanel header={t('productInfo')} key="0">
          <ProductForm
            initialValues={productFormContent}
            onSubmit={onSubmitProduct}
            title={t('updateProduct')}
            formId="update-product"
            setBrandValue={setBrandValue}
            isUpdate
            reset
          />
          <Row gutter={12}>
            <Col md={{ offset: 6, span: 4 }} xs={{ offset: 0, span: 12 }}>
              <Button
                type="primary"
                htmlType="submit"
                form="update-product"
                loading={loadingUpdate}
              >
                {t('updateProduct')}
              </Button>
            </Col>
          </Row>
        </UpdateProductPanel>
        <Panel header={t('variants')} key="1">
          <Row gutter={12}>
            <Col span={24} style={{ marginBottom: '1em' }}>
              {changedAttributeLevels && (
                <Alert
                  message={t('productForm.modal.message.changedLevels')}
                  type="warning"
                  closable
                  onClose={onCloseLevelAlert}
                />
              )}
            </Col>
          </Row>
          <Row gutter={12} justify="end">
            <Col>
              <Search
                onChange={handleSearch}
                allowClear
                placeholder={t('purchaseAutocomplete.placeholder')}
              />
            </Col>
            <Col style={{ marginBottom: '1em' }}>
              <Button
                type="primary"
                htmlType="button"
                onClick={triggerCreateForm}
              >
                {t('createVariant')}
              </Button>
              {(product?.variants?.length ?? 0) > 0 ? (
                <Button
                  type="primary"
                  htmlType="submit"
                  form="create-variant"
                  style={{ marginLeft: '5px' }}
                  onClick={onRecountModal}
                >
                  {t('recountUnits')}
                </Button>
              ) : null}
            </Col>
          </Row>
          <VariantTable
            data={variantsList ?? []}
            onDeleteVariant={onDeleteVariant}
            onUpdateVariant={onUpdateVariant}
            onGoToPWA={onGoToPWA}
          />
        </Panel>
      </Collapse>
    </UpdateProductContainer>
  );
};
